import axios from "axios";

export function getRefreshToken(baseUrl, token) { // 刷新token 注意这里用到的service
    return axios({
        method:"post",
        url:baseUrl+'/refresh/token',
        headers:{
            "Authorization":token
        }
    }).then((res)=> {
        return Promise.resolve(res.data)
    })
    // return axios.post(baseUrl+'/refresh/token',{},setHeaders)
    //     .then((res) => {
    //         return Promise.resolve(res.data)
    //     })
}
