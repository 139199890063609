import axios from 'axios';
import router from "../router";
import {getRefreshToken} from "../api/refresh";
var ui = require('element-plus')
const baseURL = 'https://wx.sipdjzc.com:8000'
const service = axios.create({
    // process.env.NODE_ENV === 'development' 来判断是否开发环境
    // easy-mock服务挂了，暂时不使用了
    baseURL: baseURL,
    timeout: 5000
});

service.interceptors.request.use(
    config => {
        const overTime = localStorage.getItem("overTime")
        let token = localStorage.getItem("token")
        if (overTime === undefined || overTime < Date.parse(new Date()) - 3600*1000) { // 3600*1000 毫秒级
            getRefreshToken(baseURL, token).then(res=>{
                token = res.token
                localStorage.setItem("token", token)
                localStorage.setItem("overTime", Date.parse(new Date()))
            })
        }
        config.headers.authorization = token
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

service.interceptors.response.use(
    response => {
        if (response.status === 200) {
            return response.data;
        }
        if (response.status === 401) {
            router.replace({
                path: "/login"
            })
            return response.data;
        }
        Promise.reject();
    },
    error => {
        ui.ElMessage({
            message: error.response.data,
            type: 'error'
        })
        return false
        // return Promise.reject(error);
    }
);

export default service;
